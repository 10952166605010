.user-details {
  width: 150px;
  text-align: center;
}

.profile-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 50%;
  height: 150px;
  width: 150px;
}

.profile-circle > img {
  width: 100%;
}

.profile-icon {
  width: 25px;
  height: 25px;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  opacity: 0.5;
}

.pic-upload-h {
  padding-bottom: 10px;
}

.add-button-div a,
.add-button-div a .btn {
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .displayTableList ._loading_overlay_wrapper {
    overflow-x: scroll;
    width: 100%;
  }
  .displayTableList ._loading_overlay_wrapper .react-bootstrap-table table {
    table-layout: auto;
  }
}
