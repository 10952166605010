.hk_custom_sag .preview_container svg{
  top:12px !important;
}

.hk_custom_sag .time_picker_container .react_times_button.time_picker_preview{
  width:95%;
  min-width: 150px;
}

.hk_custom_sag .mw-196{
  min-width: 196px;
}

.hk_custom_sag td.wsp{
  white-space: pre;
}

.hk_custom_sag .PhoneInputInput{
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5c6873;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.hk_custom_sag .auto_layout .react-bootstrap-table table {
  table-layout: auto;
}

.hk_custom_sag .form-check-input.test-price-checkbox-select-all{
  margin-top: 3px;
}


.hk_custom_sag .hk_sag_container {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  margin-bottom: 0;
  font-size: 1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.hk_custom_sag .hk_sag_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.hk_custom_sag .hk_sag_checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 18px;
  width: 18px;
  border:1px solid #a1d9f5;
  border-radius: 4px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.hk_custom_sag .hk_sag_container:hover input ~ .hk_sag_checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.hk_custom_sag .hk_sag_container input:checked ~ .hk_sag_checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.hk_custom_sag .hk_sag_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.hk_custom_sag .hk_sag_container input:checked ~ .hk_sag_checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.hk_custom_sag .hk_sag_container .hk_sag_checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hk_custom_sag .page_link{
  display: flex;
}

.hk_custom_sag .lab_tests .table thead > tr th:nth-child(1){
  width:68px;
}
.hk_custom_sag .lab_tests .table thead  > tr th:nth-child(2){
  min-width:300px;
  width:300px;
}
.hk_custom_sag .lab_tests .table thead > tr th:nth-child(3){
  min-width:150px;
  width:150px;
}
.hk_custom_sag .lab_tests .table thead > tr th:nth-child(4){
  min-width:200px;
  width:200px;
}
.hk_custom_sag .lab_tests .table thead > tr th:last-child{
  width:80px;
}

.mH-54{
  margin-top:8px;
  min-height: 54px;
}
