body {
  background-color: #fbfcfd;
}

/* -------------------- Admin Header ----------------- */
.app-header {
  height: 76px;
  padding-left: 20px;
  padding-right: 20px;
}
.app-header .navbar-brand {
  width: 215px;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.top-heading {
  font-size: 22px;
  color: #091344;
  font-weight: bold;
  margin: 0px;
}

.top-heading span {
  font-size: 14px;
  color: #a1a1a1;
  font-weight: 600;
}

.top-heading span.admin-role {
  font-size: 18px;
  color: #333;
}

.test-env {
  left: 30px;
}

.right-navbar .nav-item,
.right-navbar .nav-link {
  min-width: 2rem;
  transition: 0.3s color;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.right-navbar .nav-item .nav-link.nav-icon {
  font-size: 22px;
  color: #012970;
  margin-right: 25px;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 100%;
  padding: 10px 12px;
}

.right-navbar .nav-profile {
  color: #012970;
}

.right-navbar .nav-profile img {
  max-height: 36px;
  margin: 0px;
}

.right-navbar .nav-profile span {
  font-size: 14px;
  font-weight: 600;
}

.dropdown-menu.show {
  margin: 0;
  box-shadow: 0 8px 16px 0 rgba(169, 173, 191, 0.2);
}

.right-navbar .profile {
  min-width: 240px;
  padding-bottom: 0;
}

.right-navbar .dropdown-header {
  text-align: center;
  font-size: 15px;
  padding: 10px 25px;
  display: block;
  margin-bottom: 0;
  color: #868e96;
  white-space: nowrap;
  background: transparent;
  border-bottom: 0px;
}

.right-navbar .profile .dropdown-header h6 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
  color: #444444;
}

.right-navbar .profile .dropdown-item {
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
}

.right-navbar .dropdown-item {
  color: #29334c;
}

.right-navbar .profile i {
  color: #6e82a9;
  margin-right: 0.5rem;
  margin-left: -0.5rem;
  width: 1em;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 600 !important;
}

.right-navbar a:hover {
  color: #0581ea !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  text-decoration: none;
  background-color: #f3f3fa;
}

.right-navbar .nav-item .nav-link .badge {
  position: absolute;
  top: -8px;
  right: -18px;
  padding: 0.26rem 0.2rem;
  min-width: 2rem;
  font-size: 13px;
  line-height: 1.5;
  border-radius: 100%;
  margin: 0px;
  left: unset;
}

.bg-blue {
  background-color: #4049ec !important;
  color: #fff !important;
}

/* --------- Sidebar ------------- */
.app-body .sidebar {
  width: 240px;
  transition: all 0.3s;
  padding-left: 20px;
  padding-right: 20px;
  /* box-shadow: 0px 0px 20px 0px rgba(1, 41, 112, 0.2); */
  background-color: #fff;
  border-right: 1px solid #ccc;
  top: 76px;
  padding-top: 30px;
}

.app-body .sidebar .sidebar-nav {
  margin-bottom: 0;
  padding: 0;
}

.app-body .sidebar .sidebar-nav,
.app-body .sidebar .nav {
  width: auto;
}

.app-body .sidebar li {
  margin-bottom: 5px;
}

.app-body .sidebar .nav-dropdown.open {
  background: transparent;
}

.app-body .sidebar li a,
.app-body .sidebar .nav-dropdown.open .nav-link {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: #4d4d4d;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
}

.app-body .sidebar li a:hover {
  color: #fff !important;
  background: #0071bc !important;
}

.app-body .sidebar li a.active,
.app-body .sidebar li.nav-dropdown.open > a {
  color: #fff !important;
  background: #2e3192 !important;
}

.app-body .sidebar li.nav-dropdown.open .nav-link.active {
  background: #2e3192ad !important;
}

.app-body .sidebar .nav-icon {
  padding: 5px 10px;
  background: transparent;
  width: 30px;
  color: #898989;
}

.app-body .sidebar li a.active i,
.app-body .sidebar li a:hover i,
.app-body .sidebar li.nav-dropdown.open > a i {
  color: #fff !important;
}

.minimizer-outer {
  display: flex;
  position: relative;
  bottom: 50px;
}

.app-body .sidebar .sidebar-minimizer {
  max-width: 50px;
  position: unset;
}

.sidebar .sidebar-minimizer::before {
  background-color: rgba(0, 0, 0, 0.2);
}

.app-body .sidebar .nav-dropdown > .nav-dropdown-items {
  padding-top: 10px;
}

.sidebar .bg-info {
  background-color: #0071bc !important;
}

html:not([dir="rtl"]) .sidebar {
  margin-left: -240px;
}

/* ---------Main Container ------------- */
.main .dashboard {
  padding: 15px;
  width: 100%;
  max-width: 100%;
}

.card {
  margin-bottom: 30px;
  border: none;
  border-radius: 15px !important;
  box-shadow: 0px 0 30px 0 rgba(1, 41, 112, 0.2) !important;
}

.dashboard .info-card {
  width: auto;
}

.card.info-card.wel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.wel .card-body {
  padding-top: 20px;
}

.card-body strong {
  padding-top: 15px;
  font-size: 22px;
  color: #091344;
  font-weight: bold;
}

.card.info-card.wel img {
  max-width: 100%;
}

.welcome-text {
  display: inline-grid;
}

.admin-role {
  text-transform: capitalize;
}

/* -----------CountTrendCards-------------- */
.counts-box-outer {
  display: flex;
  gap: 15px;
}

.counts-box {
  border-right: 1px solid #ccc6;
  padding-right: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.counts-box-outer .counts-box:last-child {
  border: none;
}

.counts-top {
  min-height: 50px;
}
.counts-top,
.counts-bottom {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 10px;
}

.icon-orange {
  color: #f37308;
  background: #ffe9d7;
}

.icon-green {
  color: #33a836;
  background: #e2f3e3;
}

.icon-blue {
  color: #06b6ff;
  background: #e9f2f7;
}

.icon-red {
  color: #fc1247;
  background: #f5e9eb;
}

.card-icon {
  width: 3rem;
  font-size: 2.5rem;
  line-height: 3rem;
  text-align: center;
}

.counts-top .card-icon {
  font-size: 22px;
  line-height: 0;
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.counts-box .ps-3 {
  padding-left: 10px !important;
}

.counts-box h6 {
  font-size: 13px;
  margin-bottom: 1px;
  color: #a1a1a1;
  font-weight: 600;
}
.counts-bottom-text {
  font-size: 13px;
  color: #a1a1a1;
}

.card-body .counts-box strong {
  font-size: 13px;
}

.count-value {
  position: relative;
  top: 5px;
}

.card-body .counts-box .count-value strong {
  font-size: 17px;
}

/* --------- Dashboard ------------- */

.dashboard .card-title {
  padding: 0 0 15px 0;
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  font-family: "Poppins", sans-serif;
  border-bottom: 1px solid #ccc;
  margin-bottom: 0.75rem;
}

.dashboard .activity {
  font-size: 14px;
  border-left: 0px solid #eaf2f9;
  margin-left: 0px;
}

.members-img ul {
  display: flex;
  list-style: none;
  padding: 0px;
  justify-content: space-around;
  align-items: center;
  margin-top: 15px;
  max-width: 100%;
  flex-flow: row wrap;
}

.members-img ul li {
  flex: 1 1 auto;
  margin-bottom: 10px;
}

.members-img ul li a {
  margin-right: 10px;
}

.members-img ul li img.rounded-circle {
  border-radius: 50% !important;
  width: 44px;
}

.card-title.card-title-heading {
  display: flex;
  justify-content: space-between;
}

h5.card-title.card-title-heading span {
  color: #fff;
  border-radius: 25%;
}

.members-list-box ul {
  padding: 0px;
  border-bottom: 1px solid #ccc;
}

.members-list-box li {
  list-style: none;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  margin: 25px 0;
  justify-content: flex-start;
  align-items: center;
}

.members-list-box li img {
  width: 90px;
}

.member-details {
  margin: 0px;
  padding: 0px;
}

.member-details h2 {
  font-size: 20px;
  margin: 0px;
  padding: 0px;
}

.member-details p {
  font-size: 14px;
  margin: 0px;
  padding: 0px;
  text-transform: capitalize;
  color: #a1a1a1;
}

.members-filter-box {
  display: flex;
  gap: 20px;
  margin: 30px 0;
  flex-wrap: wrap;
}

.members-filter {
  background: #f2f3f7;
  color: #000;
  padding: 5px 20px;
  border-radius: 10px;
}

.members-filter.active {
  background: #8cc90c;
  color: #fff;
}

.viewall {
  font-weight: normal !important;
  font-size: 14px;
  color: #000;
  margin-left: 5px;
  display: inline-block;
  margin-top: 15px;
}

.upcoming-viewall {
  color: #000;
  display: inline-block;
  width: auto;
  text-align: right;
  padding: 0;
  font-weight: normal;
  font-size: 14px;
}

h5.card-title.card-title-heading .upcoming-viewall span {
  color: #000;
}

.card-title.card-title-heading .upcoming-viewall span i {
  border: 1px solid #000;
  border-radius: 50%;
  padding: 1.5px 3.5px;
  display: inline-block;
  font-weight: bold;
  font-size: 10px;
  position: relative;
  top: -1px;
}

.upcomming-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-height: 365px;
  height: 365px;
}

.upcomming-box ul {
  padding: 0px;
  overflow-y: auto;
  max-height: 365px;
  padding-right: 15px;
  height: 365px;
}

.upcomming-box ul li {
  list-style: none;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  margin: 20px 0;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 10px;
  align-items: center;
}

.upcomming-box ul li > span {
  font-size: 16px;
  text-align: center;
  padding: 10px 20px;
  font-weight: bold;
  border-radius: 15px;
  background-color: #e9e6e6;
  background-image: linear-gradient(#ffffff, #fcf5eb);
}

.upcomming-box ul li:nth-child(even) > span {
  background-image: linear-gradient(#ffffff, #ece9f3);
}

/* Track */
.dashboard ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e4e4e4 !important;
  background: #e4e4e4 !important;
  border-radius: 10px;
}

.dashboard ::-webkit-scrollbar {
  background-color: #1069eb;
  width: 4px;
}

/* Handle */
.dashboard ::-webkit-scrollbar-thumb {
  background: #1069eb !important;
  border-radius: 10px;
}

/* Handle on hover */
.dashboard ::-webkit-scrollbar-thumb:hover {
  background: #1069eb !important;
}

.add-new-event {
  margin-top: 15px;
}

.add-new-event a {
  background: #2e3192;
  color: #fff;
  font-size: 20px;
  display: block;
  text-align: center;
  padding: 15px;
  border-radius: 10px;
  font-weight: bold;
}

.add-new-event a:hover {
  color: #fff !important;
}

.card-title span {
  color: #899bbd;
  font-size: 14px;
  font-weight: 400;
}

.select-filter {
  background: #f1f5fe;
  border: 0px;
  border-radius: 5px;
  color: #1168eb;
  width: 105px;
  max-width: 100%;
  margin: 0px;
  padding: 0px;
}

.filter-h {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progress-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 20px;
}

.progress {
  height: 7px;
  margin-bottom: 15px;
}

.members-list-box a span i {
  border: 1px solid #000;
  border-radius: 50%;
  padding: 2px 6px;
  display: inline-block;
  font-weight: bold;
  font-size: 12px;
}

a:hover {
  text-decoration: none;
}

.progress-outer:last-child .progress {
  margin-bottom: 5px;
}

.card-content {
  min-height: 280px;
}

.welcome-outer {
  display: flex;
  align-items: center;
}

.welcome-text h2 {
  margin-bottom: 0px;
}

.trend-up {
  color: #61b55d;
}

.trend-down {
  color: #ef8881;
}

.bg-drug {
  background-color: rgb(255, 99, 132);
}

.bg-lab {
  background-color: rgb(54, 162, 235);
}

.bg-medical {
  background-color: rgb(255, 206, 86);
}

.progress-box-right span {
  color: gray;
}

.chartjs-render-monitor {
  margin-top: 20px;
}

.sidebar-minimized .sidebar .nav-link:hover .badge {
  display: none;
}

.order-detail-link a {
  color: inherit;
}

.mobile-header {
  border-bottom: 1px solid #c8ced3;
}

/* --------- Responsive ------------- */
@media (max-width: 1460px) {
  .test-env {
    left: 20px;
    font-size: 16px;
  }
}

@media (max-width: 1200px) {
  .top-heading {
    font-size: 18px;
  }
  .top-heading span {
    font-size: 12px;
  }
  .top-heading span.admin-role {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .header-fixed .app-body {
    margin-top: 76px;
  }
  .sidebar-fixed .app-header + .app-body .sidebar {
    height: calc(100vh - 76px);
  }
  .sidebar-minimized.sidebar-fixed .app-body .sidebar {
    width: 51px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .sidebar-minimized .app-body .sidebar .sidebar-nav {
    overflow: visible;
    width: 50px;
  }
  .sidebar-minimized .app-body .sidebar .nav {
    width: 50px;
  }
  .sidebar-minimized .app-body .sidebar .nav-link,
  .sidebar-minimized .app-body .sidebar .nav-dropdown .nav-link {
    border-radius: 0px;
    padding: 10px 0;
    display: block;
  }
  .sidebar-minimized .app-body .sidebar .nav-item:hover .nav-link {
    display: flex;
  }
  .sidebar-minimized .app-body .sidebar .nav-link .nav-icon {
    display: inline-block;
    float: none;
    width: 50px;
    border-radius: 0px;
  }
  .sidebar-minimized .app-body .sidebar .nav-item:hover > .nav-link {
    color: #fff;
    background: #0071bc;
  }

  .sidebar-minimized .app-body .sidebar .nav-item:hover > .nav-link .nav-icon {
    color: #fff;
  }

  .sidebar-minimized
    .app-body
    .sidebar
    .nav-item:hover
    > .nav-link.active
    .nav-icon {
    background: transparent !important;
  }

  .sidebar-minimized
    .app-body
    .sidebar
    .nav
    > .nav-dropdown
    > .nav-dropdown-items {
    background: #fff;
  }
  html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main,
  html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer,
  html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main,
  html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
    margin-left: 240px;
  }

  .sidebar-minimized .app-body .sidebar .sidebar-minimizer {
    position: fixed;
  }
}

@media (max-width: 991.98px) {
  .app-header .navbar-brand {
    width: 155px;
    position: unset;
    top: 0;
    left: 0;
    margin-left: 0;
  }

  .app-body {
    margin-top: 76px;
  }
}

@media (max-width: 786px) {
  .top-heading {
    display: none !important;
  }
}

@media (min-width: 576px) {
  html:not([dir="rtl"]) .sidebar-sm-show.sidebar-fixed .main,
  html:not([dir="rtl"]) .sidebar-sm-show.sidebar-fixed .app-footer,
  html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main,
  html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
    margin-left: 240px;
  }
}

@media (max-width: 575px) {
  .counts-box-outer {
    flex-flow: wrap;
    justify-content: space-around;
  }
  .counts-box {
    display: inline-flex;
  }

  .counts-box-outer .counts-box {
    border-right: none;
    border-bottom: 1px solid #ccc;
  }
}

@media (max-width: 480px) {
  .app-content .dashboard {
    margin-top: 0px !important;
  }
}
