// Here you can add other styles
.main .container-fluid {
  padding: 0 15px;
}
.displayTableList .card-header {
  display: none;
}
.displayTableList .card-body {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

.add-button-div {
  width: 100%;
  margin: 10px auto;
}

// .displayTableList .card-body a {
//     width: 50%;
// }
// .displayTableList .card-body a button, .displayTableList .card-body a + label {
//     margin-bottom: 16px;
// }
.addNewElementClass,
.displayTableList .card-body a + label {
  margin-bottom: 16px;
}
.displayTableList .react-bootstrap-table-pagination,
.displayTableList a.page-link {
  width: 100%;
  align-items: center;
}
.displayTableList ul.pagination {
  margin: 0;
}
.displayTableList span.react-bootstrap-table-pagination-total {
  padding: 16px;
}
.displayTableList button {
  margin: 0 5px;
}
// .displayTableList .card-body header {
//   width: 50%;
//   padding: 0;
//   justify-content: flex-end !important;
// }
// .displayTableList header div {
//   width: 100%;
// }
// .displayTableList .sc-kgoBCf.hFJClG {
//   display: flex;
//   justify-content: flex-start;
// }
// .displayTableList .sc-bxivhb.jIaJrt.rdt_TableHeadRow {
//   background: #e5f8ff;
// }
// .displayTableList .sc-ifAKCX.sc-EHOje.sc-VigVT.ftSrFl {}
// .displayTableList .rdt_TableHeadRow .rdt_TableCol div {
//   font-size: 14px;
//   font-weight: 600;
//   color: #2f353a;
// }
// .displayTableList .sc-jKJlTe.jDHQPG {
//   border:1px solid #cfcfcf;
// }
// .displayTableList button.btn.btn-danger.btn-sm {
//   margin: 5px;
// }
// .displayTableList .rdt_TableRow:nth-child(even) {
//   background: rgba(0, 0, 0, 0.05);
// }
// .displayTableList .rdt_TableFooter {
//     background: #e5f8ff;
// }
// .displayTableList .rdt_TableFooter {
//     font-weight: 600;
// }
// .displayTableList .rdt_TableFooter * {
//     font-weight: 600;
//     color: #2f353a;
// }

.updateStatus {
  cursor: pointer;
}

.invalid-feedback-text {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545 !important;
}
.preview-img {
  height: 100px !important;
}

li.dropdown-item {
  cursor: pointer;
}

button.sizePerPageaTag.btn.btn-secondary {
  background-color: inherit;
  border: none;
}

.dropdown-btn-col-w {
  background-color: #ffffff;
}

.dropdown-p-28 {
  padding: 2px 8px;
}

.pac-container {
  z-index: 9999 !important;
}

ul.city_list {
  padding: 2px;
  z-index: 99;
}

ul.city_list > li {
  padding: 5px;
  list-style: none;
  font-size: 12px;
  border-top: 1px solid #dadada;
  border-left: 1px solid #dadada;
  border-right: 1px solid #dadada;
}

ul.city_list > li:last-child {
  border-bottom: 1px solid #dadada;
}
ul.city_list > li > a,
ul.city_list > li > a:hover {
  color: #262727;
  text-decoration: none;
}
.w-150 {
  width: 150px;
}

.filters-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.border-custom {
  border: 1px solid #e4e7ea !important;
}

.input-group-text-custom {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5c6873;
  text-align: center;
  white-space: nowrap;
  background-color: #f0f3f5;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0px none transparent;
}
// Css for time picker
.timepicker-inline {
  display: inline-block;
}

.btn-info,
.btn-info:hover {
  color: #ffffff !important ;
}

.form-check-inline .form-check-input,
input[type="radio"],
input[type="checkbox"] {
  // margin-top: -1px;
  vertical-align: middle;
}

.form-check-input.test-price-checkbox {
  margin-top: -10px;
}

.preview_container {
  left: 45%;
}

.form-group.from_commission .form-check {
  padding: 0;
}

.form-group.from_commission .form-check .commission_label {
  width: 110px;
}

.form-group.from_commission
  .form-check
  .form-check.form-check-inline
  label.form-check-label {
  width: 50px;
}

.form-group.from_commission .form-check .form-group {
  width: 100%;
  margin: 8px 0;
}
.col-rating {
  color: #ffca00;
}
//Css for duty hours
.time_picker_container .react_times_button.time_picker_preview {
  width: 74px;
  font-size: 12px;
  margin-top: -10px;
}
.designshiv .react_times_button.time_picker_preview .preview_container {
  position: relative;
  left: 40px;
}
.designshiv .time_picker_container {
  float: left;
}
.designshiv .col-md-6.timepicker-inline label {
  float: left;
  width: auto !important;
}
.designshiv .react_times_button.time_picker_preview {
  height: 40px;
}
.row.designshiv {
  width: 290px;
  float: left;
  position: absolute;
  right: 0;
  top: 0px;
}
.dutyHourContainer.card-body .form-group {
  position: relative;
  margin-bottom: 25px;
}
.form-group.paymentpage label {
  width: 100%;
  float: left;
}
.form-group.paymentpage .form-check.form-check-inline {
  width: 47%;
  margin: 0 0 10px;
}
.form-group.paymentpage
  .form-check.form-check-inline
  label.ml-3.mr-3.form-check-label {
  margin: 0px !important;
  padding: 0;
}
.preview_container svg {
  width: 25px;
  height: 22px;
  top: 5px !important;
}
.designshiv .react_times_button.time_picker_preview {
  height: 31px;
  margin-top: -5px;
}

.card .card-body.dutyHourContainer .form-group label {
  width: 75px;
  font-size: 12px;
  margin: 2px 2px 0 !important;
}
.designshiv .preview_container {
  position: absolute;
  left: 50%;
  height: 30px;
  line-height: 30px;
  padding-left: 30px;
}
.displayInlineBlock {
  display: inline-block;
}

// css for bubble notification count
.bubbleCount {
  position: absolute;
  border-radius: 100%;
  background-color: #f30301;
  color: #fff;
}

/* Css for certificate status */
.br-lf-success {
  border-left: 6px solid #4dbd74;
}
.br-lf-danger {
  border-left: 6px solid #f63c3a;
}
.br-lf-warning {
  border-left: 6px solid #ffc107;
}

.form-horizontal .preview-img {
  margin: 10px;
}

.react-bootstrap-daterangepicker-container {
  display: block !important;
}

.datatable-action .btn.btn-sm {
  margin: 2px 5px;
  min-width: 30px;
}

// insurance card preview css
.document-preview {
  width: 400px;
  position: relative;
  padding: 10px;
  text-align: center;
  display: grid;
  cursor: pointer;
}

.document-preview .fa {
  font-size: 50px;
}

.document-preview .preview-filename {
  font-weight: 500;
}

.image-preview {
  max-width: 300px;
  border: 1px solid #000;
  cursor: pointer;
  margin-top: 10px;
}

.image-preview img {
  width: 100%;
}

// to make the sidebar bg color navy blue
.sidebar {
  background: #001f3d;
}
.sidebar .nav-link.active {
  background: #1f4165;
}

.btn-danger {
  background-color: #cc0e74;
  border-color: #cc0e74;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  background-color: #790c5a;
  border-color: #790c5a;
}

.btn-danger:hover {
  background-color: #790c5a;
  border-color: #790c5a;
}

.text-danger {
  color: #cc0e74 !important;
}

.result-download-cell .btn.btn-success {
  color: #fff;
  background-color: #2861b1;
  border-color: #2c60a9;
}

.hk_custom_sag .PhoneInputInput:disabled,
.hk_custom_sag .PhoneInputInput[readonly] {
  background-color: #e4e7ea;
  opacity: 1;
}

.enter-barcode-box {
  padding: 1px 5px;
  border-radius: 10px;
  cursor: pointer;
  width: 130px;
}

.enter-barcode-input {
  width: 150px;
  display: block;
  position: relative;
  margin-top: 10px;
}

.enter-barcode-input input {
  padding: 0 5px;
  height: 25px;
}

.enter-barcode-input .save {
  position: absolute;
  right: 3px;
  top: 3px;
  font-size: 20px;
  cursor: pointer;
}

.edit-barcode-box {
  cursor: copy;
}

.align-left {
  display: flex;
}

.admin-login-logo {
  max-width: 100%;
  width: 240px;
  margin: 0 auto;
}

.employee-view-search-input {
  align-items: center;
  display: flex;
  width: 160px;
  max-width: 200px;
}

.cap {
  text-transform: capitalize;
}

// Insurance label CSS
.in-sub-heading {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e4e6f1;
}

.in-row {
  display: grid;
  margin-bottom: 10px;
}

.in-label {
  font-weight: 500;
}

.btn.vaccinated-button {
  padding: 0px 4px;
  background-color: orange;
  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: initial;
  margin: 0 2px;
}

.btn.vaccinated-button:hover {
  color: white;
}

.bold-font {
  font-weight: 700;
}

.div-ui-row {
  background: #edededcf;
  padding: 10px;
  margin: 10px 0;
}

.base-info {
  margin: 0 0 10px;
  font-size: 16px;
}

.base-info .user-name,
.base-info .activity-time {
  font-weight: 550;
}

.other-info .row {
  padding: 2px;
}

.user-label {
  background: #afe4f5;
  padding: 2px 4px;
  border-radius: 2px;
  margin-right: 4px;
  margin-bottom: 4px;
}

.user-label.removed {
  text-decoration: line-through;
  background: lightgrey;
}

.user-label.patient {
  background: #f3bcce;
}

.a-log-data {
  display: flex;
  flex-wrap: wrap;
}

.subtest-section {
  margin-left: 20px;
}

.newline {
  display: block;
}

.test-info {
  color: #4e4a4a;
  font-size: 0.775rem;
}

.customized-section {
  padding: 10px 30px;
  border: 1px dashed #ccc;
  margin: 15px 30px;
  border-radius: 5px;
}

.customized-comtainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 200px;
  width: 100%;
}

.customized-card {
  column-count: 2;
  column-gap: 0;
  flex: 1;
}

.customized-card-item {
  display: block;
  padding: 10px;
  width: 95%;
  -webkit-column-break-inside: avoid;
  /* Chrome, Safari, Opera */
  page-break-inside: avoid;
  /* Firefox */
  break-inside: avoid;
  /* IE 10+ */
}

.custom-subtests-container {
  margin-left: 25px;
}

.mb-20 {
  margin-bottom: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.hl {
  background-color: yellow;
  padding: 0.1em 0.2em;
}

.test-disabled {
  color: #8a9095;
}

.test-disabled .test-info {
  color: #8a9095;
}

.test-label {
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

.test-cat-area {
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding: 10px 0;
}

.ml-30 {
  margin-left: 30px;
}

.btn.mro-button {
  padding: 2px 4px;
  background-color: orange;
  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: initial;
  margin: 0 2px;
}

.btn-orange {
  color: #fff;
  background-color: #e67605;
  border-color: #e67605;
}

.blue-btn,
.blue-btn:hover {
  background-color: #3372bc;
  border-color: #3372bc;
  color: #fff;
}

.orange-btn,
.orange-btn:hover {
  background-color: #d8470b;
  border-color: #d8470b;
  color: #fff;
}

.h3-red {
  color: red;
}
.test-env {
  margin: 0;
  padding: 0;
  position: relative;
  left: calc(50% - 196px);
}
.test-env-mob {
  display: none;
}
.single-result-row {
  margin-bottom: 5px;
  position: relative;
  padding-bottom: 5px;
  border-bottom: 1px solid rgb(218, 202, 202);
}

.single-result-row:last-child {
  border-bottom: none;
}

.test-card {
  display: flex;
  border: 1px solid #e5e5e5;
  padding: 2px 5px;
  margin-bottom: 5px;
}

.test-card-close {
  position: absolute;
  right: 20px;
  top: 5px;
  cursor: pointer;
}

@media (max-width: 991.98px) {
  .test-env {
    display: none;
  }
  .test-env-mob {
    display: inline-block;
    font-size: 12px;
    position: absolute;
    right: 15px;
    top: 70px;
    z-index: 1;
    margin: 0;
    padding: 0;
  }
}

.notes-card {
  margin: 10px 0;
  display: inline-flex;
  width: 100%;
  border-bottom: 1px solid #e2e6e8;
  padding-bottom: 5px;
}
.note-detail {
  color: #729da8;
}
.dis-cat-h {
  color: #0581ea;
}
.mro-obervation-info {
  padding-left: 0;
}

.mro-obervation-info li {
  line-height: 16px;
  list-style-type: none;
}

select:disabled {
  opacity: 0;
}

.fw-700 {
  font-weight: 700;
}

.btn-ui {
  display: inline-block;
  padding: 0.375rem 1.25rem;
  line-height: 1.84615385;
  letter-spacing: 0.03em;
  font-weight: 550;
  font-size: 0.96rem;
  cursor: default !important;
}

.remove-test-link,
.remove-test-link:hover {
  margin-left: 10px;
  font-size: 12px;
  color: #d97f7f;
}

.test-group-name {
  color: #808080;
  margin-top: "5px";
}

.test-name.removed {
  color: #cc0e74;
}

.z-2 {
  z-index: 2;
}

.hz-nav-internal.nav,
.hz-steps-internal.nav {
  padding: 5px;
}

.hz-nav-internal .nav-link.active {
  color: #2a79ed;
  text-decoration: underline dotted;
  pointer-events: none;
}

.hz-nav-internal .nav-link {
  color: #20a8d8;
}

.hz-steps-internal .nav-link {
  color: #000;
}

.hz-steps-internal .nav-link.filled {
  color: #20a8d8;
}

.hz-steps-internal .filled {
  cursor: pointer;
}

.hz-steps-internal .current {
  color: #0d6efd;
}

.check-label {
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

.check-label2 {
  display: block;
  margin-bottom: 10px;
  margin-left: 20px;
  text-transform: capitalize;
}

.bt-name {
  background: #ebe9e7;
  padding: 5px 10px;
  border-radius: 2px;
}

.test-label-inner {
  display: inline-block;
  margin-bottom: 10px;
  margin-left: 40px;
  text-transform: capitalize;
}

.ml-20 {
  margin-left: 20px;
}

.form-label-radio {
  display: block;
  margin-bottom: 0.375rem;
  font-weight: 500;
  font-size: 0.875rem;
}

.disable-sidebar {
  width: 100%;
  height: 100vh;
  z-index: 1;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 0.5;
}

.btn-fancy-option {
  text-align: center;
  cursor: pointer;
  margin: 0 5px;
}
.btn-fancy-option .fa {
  font-size: 30px;
  display: block;
  margin-bottom: 2px;
}

.modal-dialog.modal-sm {
  max-width: 650px !important;
}

.input-lg {
  width: 320px;
  min-height: 38px;
}

@media (min-width: 576px) {
  /* .modal-dialog {
    max-width: 75% !important;
    margin: 1.75rem auto;
  } */

  .product-pricing-modal.modal-dialog {
    max-width: 500px !important;
    margin: 1.75rem auto;
  }

  .modal-dialog.modal-sm {
    max-width: 500px !important;
  }
}

.ck-editor__main .ck-editor__editable_inline {
  min-height: 200px;
  height: 200px;
}

.negative-css {
  font-weight: 500;
}

.positive-css {
  color: red;
  font-weight: 500;
}

.inconclusive-css {
  font-weight: 400;
}

.tnp-css {
  font-weight: 400;
}

.button-css {
  margin: 2px 5px;
}

.delete-button {
  margin: 2px;
  float: right;
  color: #d97f7f;
}

.productPricingList .editor-container:hover {
  cursor: pointer;
}

.add-patient-box {
  padding: 20px 10px;
}

@media only screen and (min-width: 667px) {
  .add-patient-box {
    padding: 50px 100px;
  }
}

.w-150 {
  width: 150px;
}

.hdm-color {
  color: #f7a001;
}
